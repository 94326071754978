<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import errorHandling from "../../../mixins/errorHandling";
/**
 * Dashboard component
 */
export default {
  mixins: [errorHandling],
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Agent",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [20, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "customerName", sortable: true, label: "Customer Name" },
        {
          key: "transactionData.changes.transactionDate",
          sortable: true,
          label: "Transaction Date",
        },
        {
          key: "transactionData.changes.transactionAmount",
          sortable: true,
          label: "Transaction Amount (GHS)",
        },
        {
          key: "transactionData.changes.paymentTypeId",
          sortable: true,
          label: "Payment Type",
        },
        { key: "transactionType" },
        { key: "transactionPaymentType", label: "Individaul / Group" },
        { key: "notified" },
      ],
      /*data variables*/
      clients: [],
      todaysTransactions: [],
      loading: false,
      searchQuery: "",
      selectedValues: [],
      individualOrGroup: [],
      paymentType: [],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.todaysTransactions.length;
    },
    calculateTransactions() {
      return this.todaysTransactions
        .filter(
          (transaction) =>
            transaction.transactionType !== "Withdrawal" &&
            transaction.transactionType !== "SavingsDeposit"
        )
        .reduce((accumulator, transaction) => {
          // Check if transactionData and changes exist before accessing transactionAmount
          if (
            transaction.transactionData &&
            transaction.transactionData.changes &&
            transaction.transactionData.changes.transactionAmount !== undefined
          ) {
            // Parse the transactionAmount to ensure it's a valid number
            const transactionAmount = parseFloat(
              transaction.transactionData.changes.transactionAmount
            );

            // Check if the parsed transactionAmount is a valid number
            if (!isNaN(transactionAmount)) {
              return accumulator + transactionAmount;
            }
          }

          // If any of the checks fail, return the accumulator unchanged
          return accumulator;
        }, 0.0);
    },
    momoTransactions() {
      return this.todaysTransactions
        .filter((transaction) => {
          return (
            transaction.transactionType !== "Withdrawal" &&
            transaction.transactionType !== "SavingsDeposit" &&
            transaction.transactionData?.changes?.paymentTypeId === "2"
          );
        })
        .reduce((accumulator, transaction) => {
          return (
            accumulator +
            parseFloat(transaction.transactionData.changes.transactionAmount)
          );
        }, 0.0);
    },
    cashTransactions() {
      return this.todaysTransactions
        .filter((transaction) => {
          return (
            transaction.transactionType !== "Withdrawal" &&
            transaction.transactionType !== "SavingsDeposit" &&
            transaction.transactionData?.changes?.paymentTypeId === "1"
          );
        })
        .reduce((accumulator, transaction) => {
          return (
            accumulator +
            parseFloat(transaction.transactionData.changes.transactionAmount)
          );
        }, 0.0);
    },
    cashWithdrawals() {
      return this.todaysTransactions
        .filter((transaction) => {
          return (
            transaction.transactionType === "Withdrawal" &&
            transaction.transactionData?.changes?.paymentTypeId === "1"
          );
        })
        .reduce((accumulator, transaction) => {
          return (
            accumulator +
            parseFloat(transaction.transactionData.changes.transactionAmount)
          );
        }, 0.0);
    },

    cashCollaterals() {
      return this.todaysTransactions
        .filter((transaction) => {
          return (
            transaction.transactionType === "SavingsDeposit" &&
            transaction.transactionData?.changes?.paymentTypeId === "1"
          );
        })
        .reduce((accumulator, transaction) => {
          return (
            accumulator +
            parseFloat(transaction.transactionData.changes.transactionAmount)
          );
        }, 0.0);
    },

    filterCustomer() {
      let filteredTransactions = [...this.todaysTransactions]; // Create a copy of transactions

      if (this.searchQuery !== "") {
        const searchQueryLowerCase = this.searchQuery.toLowerCase();
        filteredTransactions = filteredTransactions.filter((transaction) =>
          transaction?.customerName === undefined
            ? false
            : transaction.customerName
                .toLowerCase()
                .includes(searchQueryLowerCase)
        );
      }

      if (this.individualOrGroup.length > 0) {
        filteredTransactions = filteredTransactions.filter((transaction) =>
          this.individualOrGroup.includes(transaction.transactionPaymentType)
        );
      }

      if (this.selectedValues.length > 0) {
        filteredTransactions = filteredTransactions.filter((transaction) =>
          this.selectedValues.includes(transaction.transactionType)
        );
      }

      if (this.paymentType.length > 0) {
        filteredTransactions = filteredTransactions.filter((transaction) =>
          transaction?.transactionData?.changes?.paymentTypeId === undefined
            ? false
            : this.paymentType.includes(
                transaction.transactionData.changes.paymentTypeId
              )
        );
      }

      return filteredTransactions;
    },
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getClients() {
      this.loading = true;
      try {
        const response = await axios.get(
          `${
            process.env.VUE_APP_BASE_URL
          }/agent/get-customers/${localStorage.getItem("id")}`
        );
        if (response.status === 200 || response.status === 201) {
          this.clients = response.data.data.pageItems;
          this.loading = false;
        } else {
          this.loading = false;
          this.throwError();
        }
      } catch (error) {
        this.handleError(error.response.data.message);
        this.loading = false;
      }
    },
    async getTransactions() {
      try {
        const response = await axios.get(
          `${
            process.env.VUE_APP_BASE_URL
          }/agent-transaction/${localStorage.getItem("id")}/today/?limit=100`
        );
        if (response.status === 200 || response.status === 201) {
          this.todaysTransactions = response.data.data.reverse();
        } else {
          this.throwError();
        }
      } catch (error) {
        this.handleError(error.response.data.message);
      }
    },
  },
  mounted() {
    this.getClients();
    this.getTransactions();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div>
          <div class="row justify-content-center">
            <div class="col-lg-5">
              <div class="text-center mb-5">
                <img
                  src="../../../assets/ppmc-logo.png"
                  class="mb-2"
                  alt
                  height="45"
                />
                <h4>Welcome ! Xefe - AGENT</h4>
                <p class="text-muted mb-4">
                  Hello , please view and access the agent dashboard here.
                </p>
              </div>
            </div>
          </div>
          <!-- end row -->
          <div class="row" v-if="loading">
            <b-spinner
              class="m-2 m-auto"
              variant="primary"
              role="status"
            ></b-spinner>
          </div>
          <div class="row" v-else>
            <div class="col-md-4">
              <router-link to="/agent/customers">
                <div class="card">
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body overflow-hidden">
                        <p class="text-truncate font-size-14 mb-2">
                          Number of Clients
                        </p>
                        <h4 class="mb-0">{{ clients.length }}</h4>
                      </div>
                      <div class="text-primary">
                        <i class="ri-user-2-line font-size-24"></i>
                      </div>
                    </div>
                  </div>

                  <div class="card-body border-top py-3">
                    <div class="text-truncate">
                      <span class="badge badge-soft-success font-size-11">
                        <i class="mdi mdi-menu-up"></i>
                        {{ clients.length }}
                      </span>
                      <span class="text-muted ml-2">Number of clients</span>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>

            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="media-body overflow-hidden">
                      <p class="text-truncate font-size-14 mb-2">
                        Loan Payments received today
                      </p>
                      <h4 class="mb-0">
                        GHS {{ calculateTransactions.toFixed(2) }}
                      </h4>
                    </div>
                    <div class="text-primary">
                      <i class="ri-wallet-3-line font-size-24"></i>
                    </div>
                  </div>
                </div>

                <div class="card-body border-top py-3">
                  <div class="text-truncate">
                    <span class="badge badge-soft-success font-size-11">
                      <i class="mdi mdi-menu-up"></i>
                      GHS {{ calculateTransactions.toFixed(2) }}
                    </span>
                    <span class="text-muted ml-2">Payment received today</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="media-body overflow-hidden">
                      <p class="text-truncate font-size-14 mb-2">
                        Momo Loan Payments Today
                      </p>
                      <h4 class="mb-0">
                        GHS {{ momoTransactions.toFixed(2) }}
                      </h4>
                    </div>
                    <div class="text-primary">
                      <i class="ri-cash-fill font-size-24"></i>
                    </div>
                  </div>
                </div>

                <div class="card-body border-top py-3">
                  <div class="text-truncate">
                    <span class="badge badge-soft-success font-size-11">
                      <i class="mdi mdi-menu-up"></i>
                      GHS {{ momoTransactions.toFixed(2) }}
                    </span>
                    <span class="text-muted ml-2">Momo</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="media-body overflow-hidden">
                      <p class="text-truncate font-size-14 mb-2">
                        Cash Loan Payments Today
                      </p>
                      <h4 class="mb-0">
                        GHS {{ cashTransactions.toFixed(2) }}
                      </h4>
                    </div>
                    <div class="text-primary">
                      <i class="ri-cash-line font-size-24"></i>
                    </div>
                  </div>
                </div>

                <div class="card-body border-top py-3">
                  <div class="text-truncate">
                    <span class="badge badge-soft-success font-size-11">
                      <i class="mdi mdi-menu-up"></i>
                      GHS {{ cashTransactions.toFixed(2) }}
                    </span>
                    <span class="text-muted ml-2">Cash</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="media-body overflow-hidden">
                      <p class="text-truncate font-size-14 mb-2">
                        Withdrawals Today
                      </p>
                      <h4 class="mb-0">GHS {{ cashWithdrawals }}</h4>
                    </div>
                    <div class="text-primary">
                      <i class="ri-cash-line font-size-24"></i>
                    </div>
                  </div>
                </div>

                <div class="card-body border-top py-3">
                  <div class="text-truncate">
                    <span class="badge badge-soft-success font-size-11">
                      <i class="mdi mdi-menu-up"></i>
                      GHS {{ cashWithdrawals }}
                    </span>
                    <span class="text-muted ml-2">Cash</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="media-body overflow-hidden">
                      <p class="text-truncate font-size-14 mb-2">
                        Cash Collaterals Deposited Today
                      </p>
                      <h4 class="mb-0">GHS {{ cashCollaterals }}</h4>
                    </div>
                    <div class="text-primary">
                      <i class="ri-cash-line font-size-24"></i>
                    </div>
                  </div>
                </div>

                <div class="card-body border-top py-3">
                  <div class="text-truncate">
                    <span class="badge badge-soft-success font-size-11">
                      <i class="mdi mdi-menu-up"></i>
                      GHS {{ cashCollaterals }}
                    </span>
                    <span class="text-muted ml-2">Cash Collateral</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-3 col-lg-4">
        <div class="card">
          <div class="card-header bg-transparent border-bottom">
            <h5 class="mb-0">Filters</h5>
          </div>

          <div class="card-body">
            <h5 class="font-size-14 mb-3">Filter By :</h5>

            <div
              id="accordion"
              class="custom-accordion categories-accordion mb-3"
            >
              <div class="categories-group-card">
                <a v-b-toggle.electorinic class="categories-group-list">
                  <i
                    class="mdi mdi-account-star-outline font-size-16 align-middle mr-2"
                  ></i>
                  Customer Name
                  <i class="mdi mdi-minus float-right accor-plus-icon"></i>
                </a>

                <b-collapse
                  id="electorinic"
                  data-parent="#accordion"
                  accordion="my-accordion"
                >
                  <div class="search-box ml-2 mt-4 mb-4">
                    <div class="position-relative">
                      <input
                        type="text"
                        class="form-control rounded"
                        v-model="searchQuery"
                        placeholder="Customer Name..."
                      />
                      <i class="mdi mdi-magnify search-icon"></i>
                    </div>
                  </div>
                </b-collapse>
              </div>

              <div class="categories-group-card">
                <a
                  v-b-toggle.trans
                  class="categories-group-list"
                  data-toggle="collapse"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  <i
                    class="mdi mdi-account-group-outline font-size-16 align-middle mr-2"
                  ></i>
                  Individual/Group
                  <i class="mdi mdi-minus float-right accor-plus-icon"></i>
                </a>
                <b-collapse
                  id="trans"
                  visible
                  data-parent="#accordion"
                  accordion="my-accordion"
                >
                  <div>
                    <div class="custom-control custom-checkbox mb-4 mt-4 ml-1">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck7"
                        v-model="individualOrGroup"
                        value="Individual"
                      />
                      <label class="custom-control-label" for="customCheck7"
                        >Individual</label
                      >
                    </div>
                    <div class="custom-control custom-checkbox mb-4 mt-4 ml-1">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck6"
                        value="Group"
                        v-model="individualOrGroup"
                      />
                      <label class="custom-control-label" for="customCheck6"
                        >Group</label
                      >
                    </div>
                  </div>
                </b-collapse>
              </div>

              <div class="categories-group-card">
                <a
                  v-b-toggle.fashion
                  class="categories-group-list"
                  data-toggle="collapse"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  <i class="mdi mdi-cash font-size-16 align-middle mr-2"></i>
                  Transaction Type
                  <i class="mdi mdi-minus float-right accor-plus-icon"></i>
                </a>
                <b-collapse
                  id="fashion"
                  visible
                  data-parent="#accordion"
                  accordion="my-accordion"
                >
                  <div>
                    <div class="custom-control custom-checkbox mb-4 mt-4 ml-1">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck1"
                        v-model="selectedValues"
                        value="SavingsDeposit"
                      />
                      <label class="custom-control-label" for="customCheck1"
                        >Cash Collateral</label
                      >
                    </div>
                    <div class="custom-control custom-checkbox mb-4 mt-4 ml-1">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck2"
                        value="LoanPayment"
                        v-model="selectedValues"
                      />
                      <label class="custom-control-label" for="customCheck2"
                        >Loan Payment</label
                      >
                    </div>
                    <div class="custom-control custom-checkbox mb-4 mt-4 ml-1">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck8"
                        value="Withdrawal"
                        v-model="selectedValues"
                      />
                      <label class="custom-control-label" for="customCheck8"
                        >Withdrawal</label
                      >
                    </div>
                  </div>
                </b-collapse>
              </div>

              <div class="categories-group-card">
                <a
                  v-b-toggle.kids
                  class="categories-group-list collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <i
                    class="mdi mdi-account-cash-outline font-size-16 align-middle mr-2"
                  ></i>
                  Payment Type
                  <i class="mdi mdi-minus float-right accor-plus-icon"></i>
                </a>
                <b-collapse
                  id="kids"
                  data-parent="#accordion"
                  accordion="my-accordion"
                >
                  <div>
                    <div class="custom-control custom-checkbox mb-4 mt-4 ml-1">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck3"
                        v-model="paymentType"
                        value="1"
                      />
                      <label class="custom-control-label" for="customCheck3"
                        >Cash Payment</label
                      >
                    </div>
                    <div class="custom-control custom-checkbox mb-4 mt-4 ml-1">
                      <input
                        type="checkbox"
                        v-model="paymentType"
                        class="custom-control-input"
                        id="customCheck4"
                        value="2"
                      />
                      <label class="custom-control-label" for="customCheck4"
                        >Momo Payment</label
                      >
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-9">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Todays Transactions Table</h4>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="filterCustomer"
                :fields="fields"
                responsive="sm"
                stacked="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template
                  v-slot:cell(transactionData.changes.paymentTypeId)="row"
                >
                  <div
                    v-if="row.value == 1"
                    class="badge badge-soft-success font-size-12"
                  >
                    Cash Payment
                  </div>
                  <div
                    v-if="row.value == 2"
                    class="badge badge-soft-warning font-size-12"
                  >
                    Momo Payment
                  </div>
                </template>
                <template v-slot:cell(transactionType)="row">
                  <div v-if="row.value == 'LoanPayment'" class="badge badge-success font-size-12">Loan Payment</div>
                  <div v-if="row.value == 'SavingsDeposit'" class="badge badge-warning font-size-12">
                    Cash Collateral
                  </div>
                  <div v-if="row.value == 'Withdrawal'" class="badge badge-danger font-size-12">Withdrawal</div>
                </template>
                <template v-slot:cell(notified)="row">
                  <div
                    v-if="row.value == true"
                    class="badge badge-soft-success font-size-12"
                  >
                    Message Sent
                  </div>
                  <div
                    v-if="row.value == false"
                    class="badge badge-soft-danger font-size-12"
                  >
                    Failed
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
